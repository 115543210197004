<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item>内容设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="menu-list">
      <div class="menu-item" @click="toUrl('banner')">
        <img :src="require('../../../../assets/img/exhibition/banner.png')"/>
        <div>banner设置</div>
      </div>
      <div class="menu-item" @click="toUrl('nav')">
        <img :src="require('../../../../assets/img/exhibition/nav.png')"/>
        <div>导航菜单</div>
      </div>
      <div class="menu-item" @click="toUrl('schedule')">
        <img :src="require('../../../../assets/img/exhibition/rc.png')"/>
        <div>日程管理</div>
      </div>
      <div class="menu-item" @click="toUrl('exhibitionhall')">
        <img :src="require('../../../../assets/img/exhibition/zg.png')"/>
        <div>展馆管理</div>
      </div>
      <div class="menu-item" @click="toUrl('pavilion')">
        <img :src="require('../../../../assets/img/exhibition/zt.png')"/>
        <div>展团管理</div>
      </div>
      <div class="menu-item" @click="toUrl('exhibitor')">
        <img :src="require('../../../../assets/img/exhibition/czs.png')"/>
        <div>参展商</div>
      </div>
      <div class="menu-item" @click="toUrl('product')">
        <img :src="require('../../../../assets/img/exhibition/zp.png')"/>
        <div>展品管理</div>
      </div>
      <div class="menu-item" @click="toUrl('meeting')">
        <img :src="require('../../../../assets/img/exhibition/hy.png')"/>
        <div>会议管理</div>
      </div>
      <div class="menu-item" @click="toUrl('info')">
        <img :src="require('../../../../assets/img/exhibition/zx.png')"/>
        <div>资讯管理</div>
      </div>
      <div class="menu-item" @click="toUrl('message')">
        <img :src="require('../../../../assets/img/exhibition/xx.png')"/>
        <div>消息记录</div>
      </div>
      <div class="menu-item" @click="toUrl('cards')">
        <img :src="require('../../../../assets/img/exhibition/mpjh.png')"/>
        <div>名片交换记录</div>
      </div>
      <div class="menu-item" @click="toUrl('negotiate')">
        <img :src="require('../../../../assets/img/exhibition/hzqt.png')"/>
        <div>洽谈室管理</div>
      </div>
      <div class="menu-item" @click="toUrl('Inquiry')">
        <img :src="require('../../../../assets/img/exhibition/xp.png')"/>
        <div>询盘管理</div>
      </div>
      <div class="menu-item" @click="toUrl('ticket')">
        <img :src="require('../../../../assets/img/exhibition/mp.png')"/>
        <div>门票管理</div>
      </div>
      <div class="menu-item" @click="toUrl('files')">
        <img :src="require('../../../../assets/img/exhibition/yhwj.png')"/>
        <div>用户文件管理</div>
      </div>
      <div class="menu-item" @click="toUrl('authentication')">
        <img :src="require('../../../../assets/img/exhibition/smrz.png')"/>
        <div>实名认证管理</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      exhibitionTitle: ''
    }
  },
  created () {
    // 获取展览名称
    this.getPageData()
  },
  methods: {
    getPageData () {
      this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
    },
    toUrl (url) {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/' + url)
    }
  }
}
</script>

<style scoped>
.menu-list{
  display: flex;
  flex-wrap: wrap;
}
.menu-list .menu-item{
  width: 200px;
  background-color: #FFFFFF;
  border-radius: 6px;
  margin: 10px;
  padding: 6px;
  cursor: pointer;
}
.menu-item:hover{
  box-shadow: rgba(20, 46, 76, 0.15) 0px 48px 100px 0px;
}
.menu-list .menu-item img{
  width: 80px;
}
.menu-list .menu-item div{
  font-size: 14px;
  padding: 4px;
}
.menu-item:hover div{
  color: #142E4F;
  font-weight: bold;
}
</style>
